<template>

  <div :class="{'uploader': true, 'btn-wide': btnWide, 'is-uploading': isUploading, 'is-disabled': !isMultiple && isUploading}"
    @dragover.prevent="handleDragOver"
    @drop.prevent="handleFileDrop"
    >

    <input 
      type="file" 
      ref="file" 
      :multiple="isMultiple" 
      allow 
      :accept="types.join(',')" 
      @change="changeFiles"
    />

    <slot></slot>

    <template v-if="enableButtons">

      <div v-if="!enableDragDrop" class="up-button">
        <div class="name"><i class="icon-plus"></i> {{ btnName }}</div>
      </div>

      <div v-else :class="{'drag-field': true, 'active' : dragging}">
        <div class="ic-area">
          <i class="icon-to-cloud"></i>
          <div class="uploading-progress">
            {{ uploadingProgressTotal }}%
          </div>
        </div>
        {{ hint }}
        <div class="up-button-area">  
          <div class="name"><i class="icon-plus"></i> {{ btnName }}</div>
        </div>

      </div>

    </template>

  </div>


</template>


<script setup lang="ts">

import { getRandomString } from '@/types/other'

const { $api } = useNuxtApp();

interface PageProps {
  hint?: string,
  url?: string | null,
  btnWide?: boolean,
  btnName?: string,
  isMultiple?: boolean,
  enableDragDrop?: boolean,
  showButton?: boolean,
  limit?: number,
  types?: string[],
  urlData?: any,
  enableButtons?: boolean
}

const props = withDefaults(defineProps<PageProps>(), {
  hint: 'Drag and drop files here',
  url: null,
  btnWide: false,
  btnName: 'Add Photos & Videos',
  isMultiple: true,
  enableDragDrop: false,
  showButton: true,
  limit: 40,
  types: () => ['image/*', 'video/*'],
  urlData: {},
  enableButtons: true
})

const emits = defineEmits([
  'uploaded',
  'errored',
  'changed',
  'progress'
])

const files = ref([])
const thumbs = ref([])
const queue = ref([])
const queueChecker = ref(null)
const isUploading = ref(false)
const file = ref(null)
const dragging = ref(false);
const uploadingProgress = ref({});



// avg progress of all files
const uploadingProgressTotal = computed(() => {
  if(Object.keys(uploadingProgress.value).length > 0){
    let total = 0;
    for (const [key, value] of Object.entries(uploadingProgress.value)) {
      total += value;
    }
    return Math.round(total / Object.keys(uploadingProgress.value).length);
  }
  return 0;
})

const handleDragOver = (e) => {
  e.preventDefault();
  dragging.value = true;
}

const handleFileDrop = (e) => {
  dragging.value = false;
  const droppedFiles = e.dataTransfer.files;
  if (droppedFiles.length > 0) {
    file.value.files = droppedFiles;
    changeFiles();
  }
}

const changeFiles = () => {  
  for( var i = 0; i < file.value.files.length; i++ ){                
    file.value.files[i].uploading_id = getRandomString(10);
    files.value.push(file.value.files[i])    
    
    const thumbnailURL = URL.createObjectURL(file.value.files[i]);
    thumbs.value.push(thumbnailURL); 

    // console.log(file.value.files[i].uploading_id);
    
    emits('changed', file.value.files[i].uploading_id, thumbnailURL); 
    // uploadFile(file.value.files[i]);
  }
  submitFiles();
}

const clearThumbnails = () => {
  thumbs.value.forEach((thumbURL) => {
    URL.revokeObjectURL(thumbURL);
  });
  thumbs.value = [];
}

const submitFiles = () => {  
  files.value.forEach(f => addQueueItem(f));
  files.value = [];
  nextStepQueue();
}

const nextStepQueue = () => { 
  if(queue.value.length > 0){
    uploadFile(queue.value[0]);
  }
}

const deleteQueueItem = (currentFile) => { 
  queue.value = queue.value.filter(f => f.uploading_id !== currentFile.uploading_id).slice();
}

const addQueueItem = (currentFile) => { 
  queue.value.push(currentFile);   
}

const uploadFile = (currentFile) => { 
  let formData = new FormData();  
  formData.append('file', currentFile, currentFile.name); 

  // apple urlData
  if(props.urlData && Object.keys(props.urlData).length > 0){
    for (const [key, value] of Object.entries(props.urlData)) {
      formData.append(key, value);
    }
  }
 
  isUploading.value = true;

  $api.postMedia(formData, (progressEvent) => {
    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    uploadingProgress.value[currentFile.uploading_id] = percentCompleted;
    
    console.log('uploadFile - progress', percentCompleted);
    console.log('--', uploadingProgress.value);

    emits('progress', percentCompleted, currentFile.uploading_id);

  }, props.url) 
  .then(response => { 
    console.log('uploadFile - response');
    clearThumbnails();
    uploadedEvent(currentFile.uploading_id, response.data);
    deleteQueueItem(currentFile);
    isUploading.value = false;
    // nextStepQueue();
  })
  .catch(error => {
    errorEvent(currentFile.uploading_id);
    deleteQueueItem(currentFile);
    // nextStepQueue();
    isUploading.value = false;
  });
}


const uploadedEvent = (uploading_id, data) => {      
  emits('uploaded', data, uploading_id); 
}

const errorEvent = (uploading_id, data) => {      
  emits('errored', uploading_id); 
}


const createQueueChecker = () => {
  queueChecker.value =  setInterval(() => {
    if(queue.value.length > 0 && !isUploading.value){
      nextStepQueue();
    }
  }, 1000);
}

const destroyQueueChecker = () => {
  if(queueChecker.value)
    clearInterval(queueChecker.value);
}

onMounted(() => {
  createQueueChecker();
})

onUnmounted(() => {
  destroyQueueChecker();
})


</script>
   

<style scoped>

.uploader{
  position: relative;
}
.is-disabled{
  pointer-events: none;
}
.up-button{
  background-color: #767676;
  color: #FFFFFF;
  display: inline-block;
  padding: 0.78571429em 1.5em 0.78571429em;
  font-weight: bold;
  line-height: 1em;
  cursor: pointer;
  font-size: 0.75rem;
  border-radius: 20px;
}
input[type="file"]{
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';  
  direction: ltr;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.name{
  width: 100%;
  text-align: center;
}

.drag-field{
  border: 1px dashed #76767617;
    width: 100%;
    height: 330px;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    flex-direction: column;
    font-weight: bold;
    background-color: var(--un-background-color-gray);
    /* max-width: 810px; */
    margin: 1rem auto;
}
.drag-field.active{
  background-color: var(--un-background-color-gray-dark);
}
.drag-field .ic-area{
  font-size: 3rem;
  aspect-ratio: 1 / 1;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--un-background-color);
  border-radius: 50%;
  line-height: 1rem;
  margin-bottom: 1rem;
  position: relative;
}
.drag-field .ic-area i{

}

.drag-field .uploading-progress{
  display: none;
  position: absolute;
  bottom: 9px;
  font-size: 0.8rem;
}


.drag-field .up-button-area{
  border: 1px var(--gd-text-color) solid;
  color: var(--gd-text-color);
  display: inline-block;
  padding: 0.78571429em 1.5em 0.78571429em;
  font-weight: bold;
  line-height: 1em;
  cursor: pointer;
  /* font-size: 0.75rem; */
  border-radius: 20px;
  margin-top: 1rem;
}



.uploader.btn-wide{
  width: 100%;
} 
.uploader.btn-wide .up-button{
  width: 100%;
}
 


/* uploading  */

.is-uploading .drag-field .uploading-progress{
  display: block;
}


/* wave animation */
.is-uploading .drag-field .ic-area i{
  animation: wave 1s infinite;
}

@keyframes wave {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/*  */


</style>
